<template>
    <div class="header-component-container">
        <div class="header-bg-container">
            <div class="header-bg-wrapper">
                <div class="header-img-container">
                    <img src="../assets/imgs/header_img.jpg" alt="header img">
                </div>
                <div class="header-bg-transparent"></div>
            </div>
        </div>
        <div class="header-contents-container">
            <div class="logo-nav-bar-wrapper">
                <div class="logo-container">
                    <div class="logo-wrapper">
                        <img src="../assets/imgs/logo.png" alt="logo img">
                    </div>
                </div>
                <div class="nav-bar-contents-container">
                    <div class="burger-menu-container" @click="toggleDisplayNavBar(!navBarDisplayed)">
                        <img :src="require(`../assets/icons/${navBarDisplayed ? 'close' : 'menu'}.png`)" alt="Menu Image">
                    </div>
                    <nav id="navBar" class="nav-bar-container" ref="navBar">
                        <div class="nav-bar-wrapper">
                            <ul v-if="wideScreen" class="nav-bar-list">
                                <li v-for="item in navBarList" class="nav-bar-item" :key="item.id">
                                    <a :href="item.navTo" @click="toggleDisplayNavBar(false)">{{ (item.title).toUpperCase() }}</a>
                                </li>
                            </ul>
                            <ul v-else class="nav-bar-list">
                                <li v-for="item in navBarList" class="nav-bar-item" :key="item.id" :style="!navBarDisplayed ? 'display: none' : 'display: block'">
                                    <a :href="item.navTo" @click="toggleDisplayNavBar(false)">{{ (item.title).toUpperCase() }}</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            <div class="quote-container">
                <div class="quote-wrapper">
                    <span>At</span>
                    <span>O.R.B</span>
                    <span>Star</span>
                    <span>Construction,</span>
                    <span>we</span>
                    <span>believe</span>
                    <span>that</span>
                    <span>great</span>
                    <span>things</span>
                    <span>are</span>
                    <span>built</span>
                    <span>on</span>
                    <span>a</span>
                    <span>solid</span>
                    <span>foundation</span>
                    <span>of</span>
                    <span>teamwork</span>
                    <span>and</span>
                    <span>innovation.</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderComponent',
    data() {
        return {
            navBarList: [
                {
                    id: 1,
                    title: 'gallery of our work',
                    navTo: '#gallery',
                },
                {
                    id: 2,
                    title: 'about us',
                    navTo: '#about',
                },
                {
                    id: 3,
                    title: 'contact us',
                    navTo: '#contact',
                },
                {
                    id: 4,
                    title: 'build with us: application form',
                    navTo: '#form',
                },
            ],
            wideScreen: false,
            navBarDisplayed: false,
            navBarBottom: 0,
        }
    },
    methods: {
        toggleDisplayNavBar: function(status) {
            this.navBarDisplayed = status;
        },
        checkWideScreen: function() {
            const screenWidth = window.innerWidth;
            if (screenWidth > 767) {
                this.wideScreen = true;
            }
            else {
                this.wideScreen = false;
            }
            this.navBarDisplayed = false;
        }
        // handleScroll() {
        //     const componentBottom = this.$el.offsetTop + this.$el.offsetHeight;
        //     const navBarBottom = this.$refs.navBar.offsetTop + this.$refs.navBar.offsetHeight;

        //     // Check if the bottom of the nav bar is outside the component
        //     let selectedNavBarBottom;
        //     if (navBarBottom == 0) {
        //         selectedNavBarBottom = this.navBarBottom;
        //     }
        //     else {
        //         selectedNavBarBottom = navBarBottom;
        //     }

        //     if (window.scrollY > componentBottom - selectedNavBarBottom) {
        //         this.isNavVisible = false; // Hide nav bar
        //     } else {
        //         this.isNavVisible = true; // Show nav bar
        //     }
        // }
    },
    mounted() {
        this.checkWideScreen();
        window.addEventListener('resize', this.checkWideScreen);
        // this.navBarBottom = this.$refs.navBar.offsetTop + this.$refs.navBar.offsetHeight;
        // window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.addEventListener('resize', this.checkWideScreen);
        // window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style>
.header-component-container {
    position: relative;
    width: 100%;
    height: 100vh;
    box-shadow: 0 8px 6px -6px gray;
}

.header-component-container .header-bg-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.header-component-container .header-bg-wrapper,
.header-component-container .header-img-container,
.header-component-container .header-img-container img,
.header-bg-container .header-bg-transparent {
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header-component-container .header-bg-wrapper {
    position: relative;
}

.header-bg-container .header-bg-transparent {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba(0, 0, 0, 0.45);
}

.header-component-container .header-contents-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header-component-container .logo-nav-bar-wrapper {
    display: flex;
}

.header-component-container .logo-container {
    padding: 15px;
    /* border: 1px solid red; */
}

.header-component-container .logo-wrapper {
    width: 10vw;
    height: 10vw;
    min-width: 80px;
    min-height: 80px;
    z-index: 2;
}

.header-component-container .logo-wrapper img {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(99%) sepia(34%) saturate(700%) hue-rotate(301deg) brightness(97%) contrast(110%);
}

.header-component-container .nav-bar-contents-container {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 10vw);
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 3;
    transition: opacity 0.3s, transform 0.3s;
    padding: 20px 20px 20px 0;
}

.header-component-container .burger-menu-container {
    display: none;
    width: 40px;
}

.header-component-container .burger-menu-container img {
    width: 100%;
}

.header-component-container .burger-menu-container img:hover {
    cursor: pointer;
}

/* .header-component-container .hidden-navbar {
    opacity: 0;
    transform: translateY(-100%);
} */

.header-component-container .nav-bar-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    perspective: 1000px;
}

/* .header-component-container .hidden {
    display: none;
} */

.header-component-container .nav-bar-list {
    display: flex;
    justify-content: space-around;
}

.header-component-container .nav-bar-list li {
    font-size: calc(12px + 0.5vw);
    font-family: 'Open Sans Std', Verdana, Geneva, Tahoma, sans-serif;
    padding: 8px;
    margin-left: 20px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff4e9;
    background: linear-gradient(300deg, #f3d49e, #575555, #743332);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
    box-shadow: 0 8px 6px -6px rgb(75, 75, 75);
    /* display: block; */
    /* border: 1px solid green; */
}

.header-component-container .nav-bar-list li:hover {
    cursor: pointer;
    background-color: #fff4e9;
    color: #f7c570;
    transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
    transition-duration: 350ms;
}

.header-component-container .quote-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 60px;
    overflow: hidden;
    /* border: 1px solid red; */
}

.header-component-container .quote-wrapper {
    width: 90%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* border: 1px solid red; */
}

.header-component-container .quote-container span {
    display: inline-block;
    margin-right: 15px;
    padding: 15px 0;
    opacity: 0;
    color: #fff4e9;
    font-size: calc(12px + 4vw);
    font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    text-align: center;
    animation: buildUp 2s forwards;
}

.header-component-container .quote-container span:last-child {
    margin-right: 0;
}

/* Assign animation */
.header-component-container .quote-container span:nth-child(odd) {
    animation-name: moveUp;
}

.header-component-container .quote-container span:nth-child(even) {
    animation-name: moveDown;
}

.header-component-container .quote-container span:nth-child(1) {
    animation-delay: 3.5s;
}

.header-component-container .quote-container span:nth-child(2) {
    animation-delay: 1.5s;
}

.header-component-container .quote-container span:nth-child(3) {
    animation-delay: 2.5s;
}

.header-component-container .quote-container span:nth-child(4) {
    animation-delay: 4s;
}

.header-component-container .quote-container span:nth-child(5) {
    animation-delay: 0.5s;
}

.header-component-container .quote-container span:nth-child(6) {
    animation-delay: 3s;
}

.header-component-container .quote-container span:nth-child(7) {
    animation-delay: 1s;
}

.header-component-container .quote-container span:nth-child(8) {
    animation-delay: 4.5s;
}

.header-component-container .quote-container span:nth-child(9) {
    animation-delay: 2s;
}

.header-component-container .quote-container span:nth-child(10) {
    animation-delay: 3.5s;
}

.header-component-container .quote-container span:nth-child(11) {
    animation-delay: 1.5s;
}

.header-component-container .quote-container span:nth-child(12) {
    animation-delay: 2.5s;
}

.header-component-container .quote-container span:nth-child(13) {
    animation-delay: 0.5s;
}

.header-component-container .quote-container span:nth-child(14) {
    animation-delay: 3s;
}

.header-component-container .quote-container span:nth-child(15) {
    animation-delay: 1s;
}

.header-component-container .quote-container span:nth-child(16) {
    animation-delay: 4s;
}

.header-component-container .quote-container span:nth-child(17) {
    animation-delay: 4.5s;
}

.header-component-container .quote-container span:nth-child(18) {
    animation-delay: 2s;
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 1199px) {
    .header-component-container {
        height: 400px;
    }

    .header-component-container .nav-bar-list li {
        font-size: calc(10px + 0.5vw);
    }

    .header-component-container .quote-container {
        padding: 0;
        /* border: 1px solid red; */
    }

    .header-component-container .quote-wrapper {
        width: 85%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */

    }

    .header-component-container .quote-container span {
        font-size: calc(12px + 2.5vw);
        margin-right: 10px;
        padding: 5px 0;
    }
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 991px) {
    .header-component-container {
        height: 350px;
    }

    .header-component-container .nav-bar-list li {
        font-size: calc(8px + 0.5vw);
    }

    .header-component-container .quote-wrapper {
        width: 80%;
    }

    .header-component-container .quote-container span {
        font-size: calc(16px + 2vw);
    }
}


/* Small to medium mobile devices (576px to 767px) */
@media (max-width: 767px) {
    .header-component-container {
        height: 300px;
    }

    .header-component-container .logo-container {
        width: 100%;
        padding: 5px 8px;
    }

    .header-component-container .burger-menu-container {
        display: block;
    }

    .header-component-container .nav-bar-list {
        width: 235px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        perspective: 1000px;
        z-index: -1;
        position: absolute;
        top: 20px;
    }

    .header-component-container .nav-bar-list li {
        width: 100%;
        padding: 15px;
        margin: 0 0 10px 0;
        text-align: center;
        opacity: 0;
    }

    .nav-bar-list li:nth-child(1) {
        transform-origin: top center;
        animation: scaleZ 300ms 60ms ease-in-out forwards;
    }
    .nav-bar-list li:nth-child(2) {
        transform-origin: top center;
        animation: scaleZ 300ms 120ms ease-in-out forwards;
    }
    .nav-bar-list li:nth-child(3) {
        transform-origin: top center;
        animation: scaleZ 300ms 180ms ease-in-out forwards;
    }
    .nav-bar-list li:nth-child(4) {
        transform-origin: top center;
        animation: scaleZ 300ms 240ms ease-in-out forwards;
    }

    .header-component-container .quote-container span {
        font-size: calc(12px + 1.5vw);
    }
}

/* Small mobile devices (320px to 576px) */
@media (min-width: 320px) and (max-width: 576px) {
    .header-component-container {
        height: 250px;
    }

    .header-component-container .logo-container {
        width: 100%;
        padding: 5px;
    }

    .header-component-container .logo-wrapper {
        min-width: 75px;
        min-height: 75px;
    }

    .header-component-container .burger-menu-container {
        width: 30px;
    }

    .header-component-container .quote-wrapper {
        width: 95%;
    }

    .header-component-container .quote-container span {
        font-size: calc(12px + 1.5vw);
    }

}

/* Smallest mobile devices (max 320px) */
@media only screen and (max-width: 320px) {
    .header-component-container {
        height: 220px;
    }

    .header-component-container .burger-menu-container {
        width: 30px;
    }

    .header-component-container .quote-wrapper {
        width: 100%;
    }

    .header-component-container .quote-container span {
        font-size: calc(12px + 0.5vw);
    }

}
</style>