<template>
    <div class="form-component-container" id="form">
        <section class="form-section-container">
            <h2 class="form-title">build with us</h2>
            <div class="form-contents-container">
                <form @submit="submitForm">
                    <div class="input-container name-container">
                        <div class="input-wrapper first-name-input-container">
                            <input id="first-name" class="form-input first-name" :class="readOnly? 'read-only' : ''" name="firstName" type="text"
                                placeholder="First Name" required />
                        </div>
                        <div class="input-wrapper last-name-input-container">
                            <input id="last-name" class="form-input" :class="readOnly? 'read-only' : ''" name="lastName" type="text" placeholder="Last Name"
                                required />
                        </div>
                    </div>

                    <div class="input-container email-container">
                        <div class="input-wrapper email-input-container">
                            <input id="email" class="form-input" :class="readOnly? 'read-only' : ''" name="email" type="email" placeholder="Email Address"
                                required />
                        </div>
                    </div>

                    <div class="input-container phone-number-container">
                        <div class="input-wrapper phone-number-input-container">
                            <div class="form-input" :class="readOnly? 'read-only' : ''">
                                <div class="country-code-container">
                                    <div class="country-flag-container">
                                        <img src="../assets/icons/usa_flag.png" alt="Country Flag"/>
                                    </div>
                                    <div class="country-code-number-container">
                                        <span>+1</span>
                                    </div>
                                </div>
                                <input id="phone-number" :class="readOnly? 'read-only' : ''" name="phoneNumber" type="tel"
                                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}" placeholder="123-456-7890" required />
                            </div>
                        </div>
                    </div>

                    <div class="input-container description-container">
                        <div class="input-wrapper description-input-container">
                            <textarea name="description" :class="readOnly? 'read-only' : ''" id="description" rows="10" cols="30"
                                placeholder="Tell us what you want to build! (Optional)"></textarea>
                        </div>
                    </div>

                    <div class="submit-btn-container">
                        <div class="submit-btn-wrapper">
                            <div v-if="submitLoading" class="loading-spinner-container">
                                <div class="circular-progress-indicator"></div>
                            </div>
                            <input v-else id="submit-btn" class="submit-btn" name="submitBtn" type="submit" value="Submit" />
                        </div>
                    </div>
                </form>
            </div>
        </section>

        <!-- Submitted form message section -->
        <div v-if="showSubmittedFormMsg" class="submitted-form-message-section-container">
            <div class="section-transparent">
                <div class="section-wrapper">
                    <div class="section-content-container">
                        <div class="status-logo-container">
                            <img :src="require(`../assets/icons/${succeededSubmittedForm ? 'like' : 'dislike'}.png`)" alt="Status Logo Image">
                        </div>
                        <div class="status-title-container">
                            <p>{{ succeededSubmittedForm ? 'AWESOME' : 'UNLUCKY' }}</p>
                        </div>
                        <div class="status-text-container">
                            <p v-if="!succeededSubmittedForm">
                                Something is not right<br/>
                                Please try again after 5 minutes or call us at the following phone numbers:<br/>
                                +1 917-440-6918 | +1 917-535-0387
                            </p>
                            <p v-else>
                                Your application has submitted successfully.<br/>
                                An email has been sent. Please check your inbox.
                            </p>
                        </div>
                        <div class="ok-btn-container" @click="closeSubmittedFormMsg">
                            <p class="ok-btn">OK</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormComponent',
    data() {
        return {
            submitLoading: false,
            readOnly: false,
            showSubmittedFormMsg: false,
            succeededSubmittedForm: false,
        }
    },
    methods: {
        submitForm: async function(e) {
            e.preventDefault();

            this.submitLoading = true;
            this.readOnly = true;
            this.showSubmittedFormMsg = false;
            this.succeededSubmittedForm = false;

            const firstNameInput = document.getElementById("first-name");
            const lastNameInput = document.getElementById("last-name");
            const emailInput = document.getElementById("email");
            const phoneNumberInput = document.getElementById("phone-number");
            const descriptionInput = document.getElementById("description");

            const countryCodeContainer = document.querySelector(".country-code-container");
            countryCodeContainer.style.background = "#b5b5b5";
            const submitBtnWrapper = document.querySelector(".submit-btn-wrapper");
            submitBtnWrapper.style.background = 'rgb(236, 236, 236)';
            const submitBtn = document.getElementById("submit-btn");

            firstNameInput.readOnly = true;
            lastNameInput.readOnly = true;
            emailInput.readOnly = true;
            phoneNumberInput.readOnly = true;
            descriptionInput.readOnly = true;
            submitBtn.readOnly = true;

            try {
                const firstName = firstNameInput.value;
                const lastName = lastNameInput.value;
                const email = emailInput.value;
                const phoneNumber = phoneNumberInput.value;
                const description = (descriptionInput.value).toString();

                const data = {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                    description: description.toString(),
                }

                console.log(data);

                const formSubmitResponse = await fetch('/api/form/submit', {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                    body: JSON.stringify(data),
                });

                const formSubmitResponseJson = await formSubmitResponse.json();

                console.log(formSubmitResponseJson);

                // Check if the form is submitted successfully or not
                if (!formSubmitResponseJson.status) {
                    // The form is not submitted successfully
                    this.submitLoading = false;
                    this.readOnly = false;
                    this.showSubmittedFormMsg = true;
                    this.succeededSubmittedForm = false;
                    firstNameInput.readOnly = false;
                    lastNameInput.readOnly = false;
                    emailInput.readOnly = false;
                    phoneNumberInput.readOnly = false;
                    descriptionInput.readOnly = false;
                    submitBtn.readOnly = false;
                    countryCodeContainer.style.background = "#ffffff";
                    submitBtnWrapper.style.background = "none";
                }
                else {
                    // The form is submitted successfully
                    this.submitLoading = false;
                    this.readOnly = false;
                    this.showSubmittedFormMsg = true;
                    this.succeededSubmittedForm = true;
                    firstNameInput.readOnly = false;
                    lastNameInput.readOnly = false;
                    emailInput.readOnly = false;
                    phoneNumberInput.readOnly = false;
                    descriptionInput.readOnly = false;
                    submitBtn.readOnly = false;
                    countryCodeContainer.style.background = "#ffffff";
                    submitBtnWrapper.style.background = "none";
                }
            }
            catch(err) {
                console.log('Error occurred while submitting the form');
                console.log(err);
                this.submitLoading = false;
                this.readOnly = false;
                this.showSubmittedFormMsg = true;
                this.succeededSubmittedForm = false;
                firstNameInput.readOnly = false;
                lastNameInput.readOnly = false;
                emailInput.readOnly = false;
                phoneNumberInput.readOnly = false;
                descriptionInput.readOnly = false;
                submitBtn.readOnly = false;
                countryCodeContainer.style.background = "#ffffff";
                submitBtnWrapper.style.background = "none";
            }
        },
        closeSubmittedFormMsg: function() {
            document.getElementById("first-name").value = '';
            document.getElementById("last-name").value = '';
            document.getElementById("email").value = '';
            document.getElementById("phone-number").value = '';
            document.getElementById("description").value = '';

            this.showSubmittedFormMsg = false;
            this.succeededSubmittedForm = false;
        }
    },
}
</script>

<style>

    .form-component-container {
        width: 100%;
    }

    .form-component-container .form-section-container {
        padding: 0 25px 40px;
    }

    .form-component-container h2 {
        padding: 25px 0;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        font-size: 40px;
        /* font-weight: bolder; */
        text-transform: uppercase;
        color: #743332;
    }

    .form-component-container .form-contents-container {
        width: 60%;
        /* min-width: 320px; */
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .form-component-container form {
        width: 100%;
        min-width: 320px;
        max-width: 500px;
        padding: 40px 0;
        /* background: rgba(0, 0, 0, 0.25); */
        background: rgba(116, 51, 50, 0.9);
        border-radius: 5px;
        box-shadow: 0 0 20px black;
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
        transition-duration: 350ms;
        /* border: 1px solid red; */
    }

    .form-component-container .input-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        /* border: 1px solid red; */
    }

    .form-component-container .input-wrapper {
        width: 100%;
        padding: 10px 5px;
        /* border: 1px solid blue; */
    }

    .form-component-container .form-input {
        width: 100%;
        padding: 12px 10px;
        border-radius: 8px;
        font-size: 20px;
        font-family: 'OpenSans Std', Verdana, Geneva, Tahoma, sans-serif;
        border: 1px solid #743332;
    }

    .form-component-container .form-input:focus {
        outline: 1px solid #743332;
        box-shadow: 0 0 20px #3c3c3c;
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
        transition-duration: 350ms;
    }

    .form-component-container .phone-number-input-container {
        width: 100%;
        border-radius: 8px;
        /* background: transparent; */
    }

    .form-component-container .phone-number-input-container .form-input {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 0;
        border: 1px solid #743332;
        border-radius: 8px;
        background: #ffffff;
        /* background: transparent; */
    }

    .form-component-container .phone-number-input-container .form-input:focus-within {
        box-shadow: 0 0 20px #3c3c3c;
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
        transition-duration: 350ms;
    }

    .form-component-container .phone-number-input-container .form-input input {
        flex: 1;
        min-width: 0;
    }

    .form-component-container .phone-number-input-container .country-code-container {
        /* height: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 9px 0px 9px 10px;
        border-radius: 8px 0 0 8px;
        background: #ffffff;
        /* border: 1px solid red; */
    }

    .form-component-container .country-flag-container {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
    }

    .form-component-container .country-flag-container img {
        width: 100%;
    }

    .form-component-container .phone-number-input-container .form-input span {
        font-size: 20px;
        font-family: 'OpenSans Std', Verdana, Geneva, Tahoma, sans-serif;
        color: #757575;
    }

    .form-component-container .phone-number-input-container .form-input input {
        flex: 1;
        font-size: 20px;
        font-family: 'OpenSans Std', Verdana, Geneva, Tahoma, sans-serif;
        padding: 12px 10px;
        border: none;
        border-radius: 0 8px 8px 0;
    }

    .form-component-container .phone-number-input-container .form-input input:focus {
        outline: none;
    }

    .form-component-container .description-input-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .form-component-container .description-input-container textarea {
        width: 100%;
        font-size: 20px;
        font-family: 'OpenSans Std', Verdana, Geneva, Tahoma, sans-serif;
        padding: 5px 10px;
        resize: none;
        border-radius: 8px;
        /* background: #d6d6d6; */
    }

    .form-component-container .description-input-container textarea:focus {
        outline: none;
        box-shadow: 0 0 20px #3c3c3c;
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.8, 0, 1);
        transition-duration: 350ms;
    }

    .form-component-container .read-only {
        background: #b5b5b5 !important;
        outline: none;

    }

    .form-component-container .read-only:focus,
    .form-component-container .phone-number-input-container .read-only:focus-within {
        box-shadow: none !important;
        transform: none !important;
        transition-duration: 0 !important;
    }

    .form-component-container .submit-btn-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 10px 10px;
        /* border: 1px solid white; */
    }

    .form-component-container .submit-btn-container .submit-btn-wrapper {
        width: 70%;
        font-size: 30px;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        background: none;
        border: 1px solid #743332;
        border-radius: 5px;
        color: #743332;
    }

    .form-component-container .submit-btn-container .loading-spinner-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        height: 47px;
        /* border: 1px solid red; */
    }

    .circular-progress-indicator {
        border: 5px solid #f3d49e;
        border-top: 5px solid #743332;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        animation: spin 1.25s linear infinite;
        margin: 0 auto;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .form-component-container .submit-btn-container .submit-btn {
        width: 100%;
        font-size: 30px;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        background: rgb(236, 236, 236);
        border: none;
        padding: 5px 0;
        border-radius: 5px;
        color: #743332;
    }

    .form-component-container .submit-btn-container .submit-btn:hover {
        /* border: 1px solid rgb(236, 236, 236); */
        border: none;
        cursor: pointer;
        box-shadow: 0 0 20px #3c3c3c;
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
        transition-duration: 350ms;
    }

    .form-component-container .submitted-form-message-section-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.4);
    }

    .form-component-container .section-wrapper {
        position: relative;
        width: 100vw;
        height: 100vh;
    }

    .form-component-container .section-content-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        max-width: 700px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgb(236, 236, 236);
        border-radius: 8px;
        box-shadow: 0 0 20px #3c3c3c;
        transition-duration: 350ms;
        /* border: 1px solid; */
    }

    .form-component-container .status-logo-container {
        width: 100px;
    }

    .form-component-container .status-logo-container img {
        width: 100%;
    }

    .form-component-container .status-title-container {
        padding: 20px 0;
    }

    .form-component-container .status-title-container p {
        font-size: 36px;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        font-weight: bolder;
        color: #743332;
        letter-spacing: 10px;
    }

    .form-component-container .status-text-container {
        font-size: 22px;
        font-family: 'OpenSans Std', Verdana, Geneva, Tahoma, sans-serif;
        line-height: 1.8em;
        text-align: center;
        padding-bottom: 25px;
        color: #743332;
        /* border: 1px solid; */
    }

    .form-component-container .status-text-container p {
        /* width: 90%; */
        margin: auto;
        font-size: 18px;
        /* letter-spacing: -1px; */
        /* border: 1px solid; */
    }

    .form-component-container .ok-btn-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
    }

    .form-component-container .ok-btn-container p {
        width: 50%;
        padding: 10px 0;
        background: #743332;
        text-align: center;
        border-radius: 8px;
        font-size: 30px;
        color: #ffffff;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    }

    .form-component-container .ok-btn-container p:hover {
        cursor: pointer;
        box-shadow: 0 0 20px #3c3c3c;
        transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.8, 0, 1);
        transition-duration: 350ms;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (max-width: 1199px) {
        .form-component-container h2 {
            font-size: calc(10px + 2.5vw);
        }
    }

    /* Small devices (tablets, 768px and up) */
    @media (max-width: 991px) {
        .form-component-container .form-input,
        .form-component-container .description-input-container textarea,
        .form-component-container .phone-number-input-container .form-input input,
        .form-component-container .phone-number-input-container .form-input span {
            font-size: calc(14px + 0.4vw);
        }
    }

    /* Small to medium mobile devices (576px to 767px) */
    @media (max-width: 767px) {
        .form-component-container .form-contents-container {
            padding: 25px 0;
        }

        .form-component-container form {
            width: 90%;
            min-width: 420px;
        }

        .form-component-container .name-container {
            flex-direction: column;
        }

        .form-component-container .name-container .input-wrapper:nth-child(1) {
            padding: 0 5px 20px;
        }

    }

    /* Small mobile devices (320px to 576px) */
    @media (min-width: 321px) and (max-width: 576px) {

        .form-component-container h2 {
            padding: 25px 0;
            margin: 0;
            text-align: center;
        }

        .form-component-container .form-contents-container {
            width: 100%;
        }

        .form-component-container form {
            width: 100%;
            min-width: 0;
        }

        .form-component-container .country-flag-container {
            width: 18px;
        }

        .form-component-container .form-input,
        .form-component-container .description-input-container textarea,
        .form-component-container .phone-number-input-container .form-input input,
        .form-component-container .phone-number-input-container .form-input span {
            font-size: 3.1vw;
        }

        .form-component-container .description-input-container textarea {
            height: 150px;
        }

        .form-component-container .submit-btn-container .submit-btn {
            width: 100%;
            font-size: calc(18px + 0.5vw);
        }
    }

    /* Smallest mobile devices (max 320px) */
    @media only screen and (max-width: 320px) {
        .form-component-container h2 {
            padding: 25px 0;
            margin: 0;
            text-align: center;
        }

        .form-component-container .form-contents-container {
            width: 90%;
        }

        .form-component-container form {
            width: 100%;
            min-width: 0;
        }

        .form-component-container .country-flag-container {
            width: 18px;
        }

        .form-component-container .form-input,
        .form-component-container .phone-number-input-container .form-input input,
        .form-component-container .phone-number-input-container .form-input span {
            font-size: 4vw;
        }

        .form-component-container .description-input-container textarea {
            font-size: 12px;
            height: 100px;
        }

        .form-component-container .submit-btn-container .submit-btn {
            width: 100%;
            font-size: calc(18px + 0.5vw);
        }
    }

</style>