<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@font-face {
  font-family: 'Open Sans Std';
  src: url('./assets/fonts/OpenSansRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'Arvo Std';
  src: url('./assets/fonts/ArvoRegular.woff2') format('woff2');
}

@keyframes appear-main {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes zoom-in {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

@keyframes zoom-out {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0);
        opacity: 0;
    }
}

/* Keyframes for moving up */
@keyframes moveUp {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Keyframes for moving down */
@keyframes moveDown {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@-moz-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-moz-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-o-keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  user-select: none;
  /* chrome and Opera */
  -moz-user-select: none;
  /* Firefox */
  -webkit-text-select: none;
  /* IOS Safari */
  -webkit-user-select: none;
  /* Safari */
}

html {
  scroll-behavior: smooth;
}

html,
body {
  max-width: 1900px;
  min-width: 320px;
  margin: 0 auto;
  animation: appear-main 2.5s;
}

body {
  position: relative;
  background: rgb(236, 236, 236);
  /* scroll-behavior: smooth; */
}

ul {
  list-style: none;
}

a {
  all: unset;
  /* Remove all styling from the a tag */
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}

@media only screen and (max-width: 1150px) {

}
</style>
