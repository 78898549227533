<template>
    <div class="footer-component-container">
        <footer>
            <div class="first-section">
                <div class="company-name-container">
                    <img src="../assets/imgs/logo.png" alt="Logo Image">
                    <p>o.r.b star construction</p>
                </div>
                <div class="visitors-number-container">
                    <p>Total Visitors: <span>{{ totalVisitorsNumber }}</span></p>
                </div>
            </div>
            <div class="copyright-container">
                <p>Copyright &copy; 2007 All Rights Reserved.</p>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
    data() {
        return {
            totalVisitorsNumber: 0,
        }
    },
    async mounted() {
        const totalVisitorsNumberResponse = await fetch('/api/form/getVisitorsNumber');
        const totalVisitorsNumberResponseJson = await totalVisitorsNumberResponse.json();

        if (!totalVisitorsNumberResponseJson.status) {
            console.log("Can not get total visitors number");
        }
        else {
            this.totalVisitorsNumber = totalVisitorsNumberResponseJson.data.totalVisitorsNumber;
        }
    }
}
</script>

<style>
    .footer-component-container {
        width: 100%;
        min-width: 325px;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #743332;
        box-shadow: 0 -8px 6px -6px gray;
    }

    .footer-component-container footer {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .footer-component-container .first-section {
        flex: 1.5;
        width: 90%;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        /* padding: 0 40px 0 20px; */
        /* padding-right: 80px; */
        /* border: 1px solid red; */
    }

    .footer-component-container .company-name-container {
        flex: 2;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* border: 1px solid red; */
    }

    .footer-component-container .company-name-container img {
        width: 40px;
        margin-right: 10px;
    }

    .footer-component-container .company-name-container p {
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff4e9;
    }

    .footer-component-container .visitors-number-container {
        flex: 1;
        display: flex;
        /* justify-content: center; */
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        font-size: 26px;
        color: #fff4e9;
        /* padding-left: 25px; */
    }

    /* .footer-component-container .visitors-number-container span {
        font-size: 30px;
    } */

    .footer-component-container .copyright-container {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: end;
        border-top: 2px solid #fff4e9;
    }

    .footer-component-container .copyright-container p {
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        font-size: 20px;
        padding-bottom: 20px;
        color: #fff4e9;
        /* border-top: 1px solid #fff4e9; */
    }

    /* Medium devices (desktops, 992px and up) */
    @media (max-width: 1199px) {

    }

    /* Small devices (tablets, 768px and up) */
    @media (max-width: 991px) {

    }

    /* Small to medium mobile devices (576px to 767px) */
    @media (max-width: 767px) {
        .footer-component-container .company-name-container p {
            font-size: calc(12px + 1vw);
        }

        .footer-component-container .copyright-container p {
            font-size: calc(10px + 1vw);
        }
    }

    /* Small mobile devices (320px to 576px) */
    @media (min-width: 321px) and (max-width: 576px) {

    }

    /* Smallest mobile devices (max 320px) */
    @media only screen and (max-width: 320px) {
        .footer-component-container .company-name-container p {
            font-size: calc(10px + 1vw);
        }

        .footer-component-container .copyright-container p {
            font-size: calc(8px + 1vw);
        }
    }
</style>