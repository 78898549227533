<template>
    <div class="gallery-component-container" id="gallery">
        <section class="gallery-section-container">
            <h2>gallery of our work</h2>
            <div class="gallery-wrapper">
                <div class="slider-container" ref="sliderContainer">
                    <ul class="slider-list" ref="sliderList">
                        <li v-for="(sliderImg, index) in sliderImgs" :key="index" class="slide-item"
                            v-on:mouseover="stopSliding" v-on:mouseout="startSliding">
                            <img :src="require(`../assets/imgs/gallery/images/${sliderImg}`)" alt="Slider Image"
                                @click="openImageViewer(require(`../assets/imgs/gallery/images/${sliderImg}`), index, 'slider')">
                        </li>

                        <li v-for="(sliderImg, index) in sliderImgs" :key="index" class="slide-item"
                            :style="!sliderItemsFirstCopyStatus ? 'display: none' : ''" v-on:mouseover="stopSliding"
                            v-on:mouseout="startSliding">
                            <img :src="require(`../assets/imgs/gallery/images/${sliderImg}`)" alt="Slider Image"
                                @click="openImageViewer(require(`../assets/imgs/gallery/images/${sliderImg}`), index, 'slider')">
                        </li>

                        <li v-for="(sliderImg, index) in sliderImgs" :key="index" class="slide-item"
                            :style="!sliderItemsSecondCopyStatus ? 'display: none' : ''" v-on:mouseover="stopSliding"
                            v-on:mouseout="startSliding">
                            <img :src="require(`../assets/imgs/gallery/images/${sliderImg}`)" alt="Slider Image"
                                @click="openImageViewer(require(`../assets/imgs/gallery/images/${sliderImg}`), index, 'slider')">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="see-full-gallery-btn-container">
                <div class="see-full-gallery-btn-wrapper" @click="openFullGallery">
                    <div class="gallery-icon-container">
                        <img src="../assets/icons/image_gallery.png" alt="Image Gallery Icon" />
                    </div>
                    <div class="see-full-gallery-btn">See Our Full Gallery</div>
                </div>
            </div>
        </section>

        <!-- Image Viewer -->
        <div id="image-viewer" ref="imageViewer">
            <span class="close-btn" @click="closeImageViewer">&times;</span>
            <div class="image-viewer-wrapper">
                <div class="image-container">
                    <img class="modal-content" id="full-image" ref="fullImage">
                </div>
                <div class="image-viewer-btns-container">
                    <div class="image-viewer-btns-wrapper">
                        <div class="image-viewer-btn-container prev-btn-container" @click="displayPrevImg">
                            <img src="../assets/icons/back-to.png" alt="Previous Image Icon">
                        </div>
                        <div class="image-viewer-btn-container next-btn-container" @click="displayNextImg">
                            <img src="../assets/icons/next.png" alt="Next Image Icon">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Full Gallery Screen -->
        <div class="full-gallery-container" ref="fullGallery">
            <div class="full-gallery-header-container">
                <div class="logo-container">
                    <img src="../assets/imgs/logo.png" alt="Logo Image">
                </div>
                <div class="title-container">
                    <p class="full-gallery-title">Our Gallery</p>
                </div>
                <span class="close-btn" @click="closeFullGallery">&times;</span>
            </div>
            <div class="full-gallery-imgs-list-container">
                <ul class="full-gallery-imgs-list" ref="fullGalleryList">
                    <li v-for="(fullGalleryImg, index) in fullGalleryImgs" :key="index" class="full-gallery-item">
                        <img :src="require(`../assets/imgs/gallery/images/full_gallery/${fullGalleryImg}`)" alt="Gallery Image"
                            @click="openImageViewer(require(`../assets/imgs/gallery/images/full_gallery/${fullGalleryImg}`), index, 'gallery')">
                    </li>
                </ul>
            </div>
            <FooterComponent />
        </div>
        <div class="horizontal-separator"></div>
    </div>
</template>

<script>
import FooterComponent from './FooterComponent.vue';
export default {
    name: 'GalleryComponent',
    data() {
        return {
            sliderImgs: this.$store.sliderImgsArr,
            fullGalleryImgs: this.$store.fullGalleryImgsArr,
            intervalId: null, // Store the interval ID so it can be cleared later
            slideDistance: 1, // This value represents how far to slide each interval (in pixels)
            stopPosition: 0, // The position at which sliding should stop
            sliderItemsFirstCopyStatus: false,
            sliderItemsSecondCopyStatus: false,
            resetSliderListStatus: false, // Reset the slider list to the default items
            sliderItemsAddedCounter: 0,
            displayedItemsWidth: 0, // The width of all currently visible slider images combined
            sliderList: null, // Reference to the ul element containing the slider images
            imgGroup: '',
        }
    },
    components: {
        FooterComponent,
    },
    methods: {
        startSliding() {
            this.intervalId = setInterval(() => {
                const itemWidth = this.displayedItemsWidth / 3;
                let stopPosition;
                const currentMargin = parseInt(this.sliderList.style.marginLeft || 0, 10);
                if (this.sliderItemsAddedCounter == 1) {
                    stopPosition = itemWidth * this.sliderImgs.length;
                }
                else {
                    stopPosition = itemWidth * (this.sliderImgs.length - 3);
                }
                this.stopPosition++;
                if (stopPosition >= this.stopPosition) {
                    this.sliderList.style.marginLeft = `${currentMargin - this.slideDistance}px`;
                    if (this.stopPosition == (itemWidth * 3)) {
                        if (this.resetSliderListStatus) {
                            this.sliderItemsFirstCopyStatus = false;
                            this.sliderItemsSecondCopyStatus = false;
                            this.sliderList.style.marginLeft = `0`;
                            this.resetSliderListStatus = false;
                            this.sliderItemsAddedCounter = 0;
                            this.stopPosition = 0;
                        }
                    }
                }
                else {
                    switch (this.sliderItemsAddedCounter) {
                        case 0: {
                            this.sliderItemsFirstCopyStatus = true;
                            this.sliderItemsSecondCopyStatus = false;
                        }
                            break;
                        case 1: {
                            this.sliderItemsFirstCopyStatus = true;
                            this.sliderItemsSecondCopyStatus = true;
                            this.resetSliderListStatus = true;
                        }
                            break;
                    }
                    this.stopPosition = 0; // Reset stop position
                    this.sliderItemsAddedCounter++;
                }
            }, 10);
        },
        stopSliding() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        // Method to open the image viewer
        openImageViewer(clickedImgSrc, clickedImgId, listName) {
            // Update the src of the full-image to the clicked image's src
            this.$refs.fullImage.src = clickedImgSrc;
            this.$refs.fullImage.key = clickedImgId;
            if (listName == 'slider') {
                this.$refs.sliderList.style.pointerEvents = 'none';
            }

            if (listName == 'gallery') {
                this.$refs.fullGalleryList.style.pointerEvents = 'none';
            }

            // Set image group to know if the image is from the slider array or the full gallery array
            this.imgGroup = listName;

            document.body.style.overflow = 'hidden';
            this.$refs.imageViewer.classList.remove('zoom-out');
            // Show the image-viewer container
            this.$refs.imageViewer.style.display = 'block';
        },
        closeImageViewer() {
            this.$refs.imageViewer.classList.add('zoom-out');
            document.body.style.overflow = '';

            // Reset image group value
            this.imgGroup = '';

            setTimeout(() => {
                this.$refs.imageViewer.style.display = 'none';
                if (this.$refs.sliderList) {
                    this.$refs.sliderList.style.pointerEvents = '';
                }
                if (this.$refs.fullGalleryList) {
                    this.$refs.fullGalleryList.style.pointerEvents = '';
                }
            }, 600);
        },
        // Move to next image inside the image-viewer
        displayNextImg() {
            console.log("this.imgGroup: " + this.imgGroup);
            const currentImgKey = this.$refs.fullImage.key;
            const nextImgKey = currentImgKey + 1;
            let imgUrl, nextImgSrc;
            let imgsGroupArr = [];

            if (this.imgGroup == 'slider') {
                imgUrl = 'assets/imgs/gallery/images/';
                imgsGroupArr = this.$store.sliderImgsArr;
            }
            else if (this.imgGroup == 'gallery') {
                imgUrl = 'assets/imgs/gallery/images/full_gallery/';
                imgsGroupArr = this.$store.fullGalleryImgsArr;
            }
            if (nextImgKey >= imgsGroupArr.length) {
                nextImgSrc = require(`../${imgUrl}${imgsGroupArr[0]}`);
                this.$refs.fullImage.src = nextImgSrc;
                this.$refs.fullImage.key = imgsGroupArr.indexOf(imgsGroupArr[0]);
            }
            else {
                imgsGroupArr.forEach(img => {
                    if (imgsGroupArr.indexOf(img) == nextImgKey) {
                        this.$refs.fullImage.src = require(`../${imgUrl}${img}`);
                        this.$refs.fullImage.key = imgsGroupArr.indexOf(img);
                    }
                });
            }
        },
        displayPrevImg() {
            const currentImgKey = this.$refs.fullImage.key;
            const nextImgKey = currentImgKey - 1;
            let imgUrl,nextImgSrc;
            let imgsGroupArr = [];

            if (this.imgGroup == 'slider') {
                imgUrl = 'assets/imgs/gallery/images/';
                imgsGroupArr = this.$store.sliderImgsArr;
            }
            else if (this.imgGroup == 'gallery') {
                imgUrl = 'assets/imgs/gallery/images/full_gallery/';
                imgsGroupArr = this.$store.fullGalleryImgsArr;
            }

            if (nextImgKey <= 0) {
                nextImgSrc = require(`../${imgUrl}${imgsGroupArr[imgsGroupArr.length - 1]}`);
                this.$refs.fullImage.src = nextImgSrc;
                this.$refs.fullImage.key = imgsGroupArr.indexOf(imgsGroupArr[imgsGroupArr.length - 1]);
            }
            else {
                imgsGroupArr.forEach(img => {
                    if (imgsGroupArr.indexOf(img) == nextImgKey) {
                        this.$refs.fullImage.src = require(`../${imgUrl}${img}`);
                        this.$refs.fullImage.key = imgsGroupArr.indexOf(img);
                    }
                })
            }
        },
        openFullGallery() {
            this.$refs.fullGallery.classList.add('zoom-in');
            this.$refs.fullGallery.classList.remove('zoom-out');
            // Show the image-viewer container
            this.$refs.fullGallery.style.display = 'block';
            document.body.style.overflow = 'hidden';
        },
        closeFullGallery() {
            document.body.style.overflow = '';
            this.$refs.fullGallery.classList.remove('zoom-in');
            this.$refs.fullGallery.classList.add('zoom-out');
            setTimeout(() => {
                this.$refs.fullGallery.style.display = 'none';
            }, 600);
        },

    },
    mounted() {
        this.sliderList = this.$refs.sliderList;
        this.displayedItemsWidth = this.sliderList.offsetWidth;
        this.startSliding();
    },
}
</script>


<style>
@keyframes slide {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(calc(-100% / 11 * 11));
    }
}

.gallery-component-container {
    width: 100%;
    padding: 50px 0 25px 0;
    /* border: 1px solid red; */
}

.gallery-component-container h2 {
    padding: 0 0 20px 25px;
    font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    color: #743332;
}

.gallery-component-container .gallery-wrapper {
    width: calc(100% - 50px);
    margin: auto;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    /* border: 1px solid red; */
}

.gallery-component-container .slider-container {
    width: 100%;
    border-left: 2px solid #f3d49e;
    border-right: 2px solid #f3d49e;
    /* overflow: hidden; */
}

.gallery-component-container .slider-list {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.gallery-component-container .slider-list li {
    flex: 0 0 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 0 calc(10% / 6);
}

.gallery-component-container .slider-list li:hover {
    cursor: pointer;
}

.gallery-component-container .slider-list li img {
    display: block;
    width: 100%;
    max-height: 250px;
    border-radius: 10px;
    border: 3px solid #f3d49e;
    box-shadow: 0 10px 6px -6px gray;
}

.gallery-component-container .slider-list li img:hover {
    /* cursor: pointer; */
    box-shadow: 0 0 20px black;
    transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
    transition-duration: 350ms;
}

.gallery-component-container .see-full-gallery-btn-container {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 25px 25px 25px 0;
    /* border: 1px solid red; */
}

.gallery-component-container .see-full-gallery-btn-wrapper {
    width: max-content;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
}

.gallery-component-container .see-full-gallery-btn-wrapper:hover {
    cursor: pointer;
    transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
    transition-duration: 350ms;
    /* font-size: 24px; */
}

.gallery-component-container .gallery-icon-container {
    width: 3vw;
    max-width: 35px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    /* padding: 2px; */
}

.gallery-component-container .gallery-icon-container img {
    width: 100%;
}

.gallery-component-container .see-full-gallery-btn {
    width: max-content;
    color: #743332;
    font-size: 20px;
    font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    transition: text-shadow 0.4s ease;
    /* cursor: pointer; */
}

.gallery-component-container .see-full-gallery-btn-wrapper:hover .see-full-gallery-btn {
    text-shadow: 1px 1px 2px #000000;
    font-weight: bold;
    /* color: #fff4e9; */
}

.gallery-component-container .see-full-gallery-btn-wrapper:hover img {
    filter: drop-shadow(5px 5px 5px #222);
}

.zoom-in {
    animation: zoom-in 0.6s forwards;
}

.zoom-out {
    animation: zoom-out 0.6s forwards;
}

.gallery-component-container #image-viewer {
    display: none;
    position: fixed;
    z-index: 99;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.gallery-component-container .image-viewer-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5%;
    /* border: 1px solid #fff; */
}

.gallery-component-container .image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid #fff; */
}


.gallery-component-container .modal-content {
    width: 80%;
    max-width: 1000px;
    max-height: 80vh;
    display: block;
    animation-name: zoom-in;
    animation-duration: 0.6s;
}

.gallery-component-container .image-viewer-btns-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.gallery-component-container .image-viewer-btns-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.gallery-component-container .image-viewer-btn-container {
    position: absolute;
    width: 5vw;
    min-width: 55px;
    top: calc(50% - 50px);
}

.gallery-component-container .image-viewer-btn-container:hover {
    cursor: pointer;
}

.gallery-component-container .image-viewer-btn-container img {
    width: 100%;
}

.gallery-component-container .image-viewer-btn-container:hover img {
    filter: drop-shadow(0px 2px 2px #c7c7c7);
}

.gallery-component-container .prev-btn-container {
    left: 25px;
}

.gallery-component-container .next-btn-container {
    right: 25px;
}

.gallery-component-container .full-gallery-container {
    display: none;
    position: fixed;
    z-index: 75;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 320px;
    height: 100%;
    overflow-y: scroll;
    background-color: #fff4e9;
}

.gallery-component-container .full-gallery-header-container {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background: linear-gradient(300deg,#f3d49e,#575555,#743332);
    background-size: 180% 180%;
    animation: gradient-animation 18s ease infinite;
    padding: 25px 40px;
    box-shadow: 0 8px 6px -6px gray;
}

.gallery-component-container .full-gallery-header-container .logo-container {
    width: 135px;
}

.gallery-component-container .full-gallery-header-container .logo-container img {
    width: 100%;
}

.gallery-component-container .full-gallery-container .full-gallery-title {
    padding: 40px 20px;
    font-size: 60px;
    color: #fff4e9;
}

.gallery-component-container #image-viewer .close-btn,
.gallery-component-container .full-gallery-container .close-btn {
    position: absolute;
    top: 15px;
    right: 35px;
    font-size: 60px;
    font-weight: bold;
    transition: 0.3s;
    color: #fff4e9;
    z-index: 100;
}

.gallery-component-container #image-viewer .close-btn:hover,
.gallery-component-container #image-viewer .close-btn:focus,
.gallery-component-container .full-gallery-container .close-btn:hover,
.gallery-component-container .full-gallery-container .close-btn:focus {
    text-decoration: none;
    cursor: pointer;
    text-shadow: 1px 1px 2px #000000;
    font-weight: bold;
    transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
    transition-duration: 350ms;
}

.gallery-component-container .full-gallery-imgs-list {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
}

.gallery-component-container .full-gallery-imgs-list li {
    flex: 0 0 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 10px calc(10% / 6);
    background: #fff4e9;
}

.gallery-component-container .full-gallery-imgs-list li img {
    display: block;
    width: 100%;
    /* height: 100%; */
    border-radius: 10px;
    border: 3px solid #f3d49e;
    box-shadow: 0 10px 6px -6px gray;
}

.gallery-component-container .full-gallery-imgs-list li img:hover {
    cursor: pointer;
    box-shadow: 0 0 20px black;
    transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
    transition-duration: 350ms;
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 1199px) {
    .gallery-component-container h2 {
        font-size: calc(10px + 2.5vw);
    }

    .gallery-component-container .see-full-gallery-btn {
        font-size: calc(10px + 1vw);
    }

    .gallery-component-container .full-gallery-header-container .logo-container {
        width: 11vw;
        min-width: 90px;
    }

    .gallery-component-container .full-gallery-container .full-gallery-title {
        font-size: calc(20px + 2.5vw);
    }

    .gallery-component-container .full-gallery-container .close-btn,
    .gallery-component-container #image-viewer .close-btn {
        font-size: 50px;
    }
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 991px) {
    .gallery-component-container .slider-list li {
        flex: 0 0 45%;
    }    

    .gallery-component-container .image-viewer-wrapper {
        flex-direction: column;
        justify-content: center;
    }

    .gallery-component-container .image-container {
        margin-bottom: 50px;
    }

    .gallery-component-container .modal-content {
        width: 95%;
    }

    .gallery-component-container .image-viewer-btns-container,
    .gallery-component-container .image-viewer-btn-container {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
    }

    .gallery-component-container .image-viewer-btns-container {
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .gallery-component-container .image-viewer-btns-wrapper {
        widows: 50%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .gallery-component-container .full-gallery-imgs-list li {
        flex: 0 0 45%;
    }
}

/* Small to medium mobile devices (576px to 767px) */
@media (max-width: 767px) {
    .gallery-component-container .gallery-wrapper {
        max-height: 250px;
    }

    .gallery-component-container .gallery-icon-container {
        width: 20px;
    }

    .gallery-component-container .see-full-gallery-btn {
        font-size: 16px;
    }

    .gallery-component-container .full-gallery-imgs-list {
        justify-content: center;
        /* border: 1px solid red; */
    }

    .gallery-component-container .full-gallery-imgs-list li {
        flex: 0 0 90%;
    }
}

/* Small mobile devices (320px to 576px) */
@media (min-width: 321px) and (max-width: 576px) { /* (min-width: 320px) and (max-width: 576px) */
    .gallery-component-container h2 {
        padding: 0 0 25px 0;
        margin: 0;
        text-align: center;
    }

    .gallery-component-container .slider-list li {
        flex: 0 0  325px;
    }

    .gallery-component-container .see-full-gallery-btn-container {
        justify-content: center;
        padding: 25px 0;
    }

    .gallery-component-container .full-gallery-header-container {
        justify-content: center;
    }

    .gallery-component-container .full-gallery-header-container .logo-container {
        width: 8vw;
        min-width: 75px;
    }

    .gallery-component-container .full-gallery-container .full-gallery-title {
        font-size: calc(20px + 0.7vw);
    }

    .gallery-component-container .full-gallery-container .close-btn,
    .gallery-component-container #image-viewer .close-btn {
        top: 10px;
        right: 20px;
        font-size: 40px;
    }
}

/* Smallest mobile devices (max 320px) */
@media only screen and (max-width: 320px) {

    .gallery-component-container h2 {
        padding: 0 0 25px 0;
        margin: 0;
        text-align: center;
        font-size: 17px;
    }

    .gallery-component-container .gallery-wrapper {
        max-height: 200px;
    }

    .gallery-component-container .slider-list li {
        flex: 0 0 90%;
    }

    .gallery-component-container .see-full-gallery-btn-container {
        justify-content: center;
        padding: 25px 0;
    }

    .gallery-component-container .gallery-icon-container {
        width: 20px;
    }

    .gallery-component-container .see-full-gallery-btn {
        font-size: calc(14px + 0.5vw);
    }

    .gallery-component-container .full-gallery-header-container {
        justify-content: center;
        padding: 25px 0;
    }

    .gallery-component-container .full-gallery-header-container .logo-container {
        min-width: 60px;
    }

    .gallery-component-container .full-gallery-container .full-gallery-title {
        font-size: calc(20px + 0.7vw);
    }

    .gallery-component-container .full-gallery-container .close-btn,
    .gallery-component-container #image-viewer .close-btn {
        top: 10px;
        right: 20px;
        font-size: 40px;
    }
}
</style>