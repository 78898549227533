import { reactive, createApp } from 'vue';
// import Vue from "vue";
import App from './App.vue';
import router from "./router/router";

const app = createApp(App);

app.use(router);


// Vue.config.productionTip = false;

// Create reactive data store
const store = reactive({
    sliderImgsArr: [
        "living_room_01.jpg",
        "living_room_02.jpg",
        "living_room_03.jpg",
        "kitchen_01.jpg",
        "kitchen_02.jpg",
        "kitchen_03.jpg",
        "bed_room_01.jpg",
        "bath_room_01.jpg",
        "bath_room_02.jpg",
        "office_room_01.jpg",
        "wardrobes_01.png",
        "wardrobes_02.png",
    ],
    fullGalleryImgsArr: [
        "pro_0001_pic_0001.jpg",
        "pro_0001_pic_0002.jpg",
        "pro_0001_pic_0003.jpg",
        "pro_0002_pic_0001.jpg",
        "pro_0002_pic_0002.jpg",
        "pro_0002_pic_0003.jpg",
        "pro_0002_pic_0004.jpg",
        "pro_0002_pic_0005.jpg",
        "pro_0002_pic_0006.jpg",
        "pro_0002_pic_0007.jpg",
        "pro_0002_pic_0008.jpg",
        "pro_0002_pic_0009.jpg",
        "pro_0002_pic_0010.jpg",
        "pro_0002_pic_0011.jpg",
        "pro_0002_pic_0012.jpg",
        "pro_0002_pic_0013.jpg",
        "pro_0002_pic_0014.jpg",
        "pro_0002_pic_0015.jpg",
        "pro_0002_pic_0016.jpg",
        "pro_0002_pic_0017.jpg",
        "pro_0002_pic_0018.jpg",
        "pro_0003_pic_0001.jpg",
        "pro_0003_pic_0002.jpg",
        "pro_0003_pic_0003.jpg",
        "pro_0003_pic_0004.jpg",
        "pro_0003_pic_0005.jpg",
        "pro_0003_pic_0006.jpg",
        "pro_0003_pic_0007.jpg",
        "pro_0003_pic_0008.jpg",
        "pro_0003_pic_0009.jpg",
        "pro_0003_pic_0010.jpg",
        "pro_0003_pic_0011.jpg",
        "pro_0004_pic_0001.jpg",
        "pro_0004_pic_0002.jpg",
        "pro_0004_pic_0003.jpg",
        "pro_0004_pic_0004.png",
        "pro_0004_pic_0005.png",
        "pro_0004_pic_0006.jpg",
        "pro_0004_pic_0007.png",
        "pro_0004_pic_0008.png",
        "pro_0004_pic_0009.png",
        "pro_0004_pic_0010.png",
    ]
});

// Make the store globally available
app.config.globalProperties.$store = store;

app.mount('#app');

// createApp(App).use(router).mount('#app');


