<template>
    <div class="about-us-component-container" id="about">
        <section class="about-us-section-container">
            <h2 class="about-us-title">about us</h2>
            <div class="about-us-contents-container">
                <div class="about-us-contents-wrapper">
                    <div class="about-us-text-container">
                        <p>
                            O.R.B Star Construction is all about designing Dream Homes for our clients.
                            We embrace the profound reality that a home is not just an ordinary place; it embodies the
                            essence
                            of its residents&#39; personalities.
                            It is a place where dreams are nurtured, ambitions are realized and greatness is forged.
                            It is also the most prized possession most of us hold.
                            Therefore it is equally important for us.
                            Designing homes is not just our business, it is our passion.
                            We put our blood and sweat to bring your vision to life. O.R.B. Star Construction is a
                            complete
                            general construction company in the business since 2008.
                            We are a full scale construction company service in Brooklyn.
                            Our team of designers and in-house licensed contractors can take you through every step of
                            the
                            remodeling process bringing your dream home to life.
                        </p>
                    </div>
                    <div class="about-us-imgs-container">
                        <div class="about-us-imgs-wrapper">
                            <div class="logo-container">
                                <img src="../assets/imgs/logo.png" alt="Logo Image">
                            </div>
                            <div class="animated-text-container">
                                <div class="animated-text-wrapper wavy">
                                    <div class="wavy-01-text-container">
                                        <span style="--i:1">o</span>
                                        <span style="--i:2">.</span>
                                        <span style="--i:3">r</span>
                                        <span style="--i:4">.</span>
                                        <span style="--i:5">b</span>
                                    </div>
                                    <div class="wavy-02-text-container">
                                        <span style="--i:6">s</span>
                                        <span style="--i:7">t</span>
                                        <span style="--i:8">a</span>
                                        <span style="--i:9">r</span>
                                        <span style="--i:10">c</span>
                                        <span style="--i:11">o</span>
                                        <span style="--i:12">n</span>
                                        <span style="--i:13">s</span>
                                        <span style="--i:14">t</span>
                                        <span style="--i:15">r</span>
                                        <span style="--i:16">u</span>
                                        <span style="--i:17">c</span>
                                        <span style="--i:18">t</span>
                                        <span style="--i:19">i</span>
                                        <span style="--i:20">o</span>
                                        <span style="--i:21">n</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="horizontal-separator"></div>
    </div>
</template>

<script>
export default {
    name: 'AboutUsComponent',

}
</script>

<style>
.about-us-component-container {
    width: 100%;
    /* padding: 50px 0; */
}

.about-us-component-container h2 {
    padding: 0 0 20px 25px;
    font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    font-size: 40px;
    /* font-weight: bolder; */
    text-transform: uppercase;
    color: #743332;
    /* border: 1px solid; */
}

.about-us-component-container .about-us-contents-container {
    width: 100%;
    padding: 0 25px 40px 25px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    /* border: 1px solid red; */
}

.about-us-component-container .about-us-contents-wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
    /* flex-wrap: wrap; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    /* border: 1px solid blue; */
}

.about-us-component-container .about-us-text-container {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding-right: 40px; */
}

.about-us-component-container .about-us-text-container p {
    flex: 1;
    font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    font-size: calc(8px + 1vw);
    line-height: 1.6em;
    text-align: justify;
    padding: 25px;
    overflow: auto;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px; */
    border-right: 2px solid #743332;
    /* border: 1px solid red; */
}

.about-us-component-container .about-us-imgs-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding-left: 25px; */
    /* border-left: 2px solid #743332; */
    /* border: 1px solid red; */
}

.about-us-component-container .about-us-imgs-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 25px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    background-color: #743332;
    background-image: linear-gradient(225deg, #743332 37%, #f3d49e 100%);
    /* border: 1px solid red; */
}

.about-us-component-container .logo-container {
    width: 80%;
    height: 100%;
    /* min-height: 400px; */
    /* min-width: 320px; */
    padding: 25px 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.about-us-component-container .logo-container img {
    width: 80%;
    /* width: 100%; */
    /* border: 1px solid red; */
}

.about-us-component-container .animated-text-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 20px 10px;
    /* border: 1px solid red; */
}

.about-us-component-container .wavy div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-component-container .wavy span {
    font-size: calc(10px + 1vw);
    font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    animation: wavy 2.5s infinite;
    animation-delay: calc(0.1s * var(--i));
}

.about-us-component-container .wavy .wavy-02-text-container span:nth-child(4)::after {
    content: ' ';
    display: inline-block;
    width: 10px;
}

@keyframes wavy {

    0%,
    40%,
    100% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-10px);
    }
}

/* Medium devices (desktops, 992px and up) */
@media (max-width: 1199px) {
    .about-us-component-container h2 {
        font-size: calc(10px + 2.5vw);
    }

    .about-us-component-container .wavy span {
        font-size: calc(8px + 1.1vw);
    }
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 991px) {
    .about-us-component-container .about-us-text-container p {
        font-size: calc(8px + 0.85vw);
    }

    .about-us-component-container .about-us-imgs-container {
        max-width: 250px;
        margin: 0 25px;
    }
}

/* Small to medium mobile devices (576px to 767px) */
@media (max-width: 767px) {
    .about-us-component-container .about-us-contents-wrapper {
        flex-direction: column-reverse;
        box-shadow: none;
        transition: none;
        border-radius: 0;
    }

    .about-us-component-container .about-us-text-container {
        margin-top: 25px;
    }

    .about-us-component-container .about-us-text-container p {
        border: none;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
    }

    .about-us-component-container .about-us-imgs-container {
        min-width: 215px;
    }
}

/* Small mobile devices (320px to 576px) */
@media (min-width: 321px) and (max-width: 576px) {
    .about-us-component-container h2 {
        padding: 0 0 25px 0;
        margin: 0;
        text-align: center;
    }
}

/* Smallest mobile devices (max 320px) */
@media only screen and (max-width: 320px) {
    .about-us-component-container h2 {
        padding: 0 0 25px 0;
        margin: 0;
        text-align: center;
    }
}
</style>