<template>
    <div class="home-page-component-container">
        <div v-show="scrollTopBtnIsVisible" id="scrollTopBtn" class="navigator-btn-container" @click="scrollToTop">
            <img src="../assets/icons/up_arrow.png" alt="Up Arrow Image">
        </div>
        <HeaderComponent ref="headerComponent"/>
        <main>
            <GalleryComponent />
            <AboutUsComponent />
            <ContactUsComponent />
            <FormComponent />
        </main>
        <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import GalleryComponent from './GalleryComponent.vue';
import AboutUsComponent from './AboutUsComponent.vue';
import ContactUsComponent from './ContactUsComponent.vue';
import FormComponent from './FormComponent.vue';
import FooterComponent from './FooterComponent.vue';

export default {
    name: 'HomePage',
    data() {
        return {
            scrollTopBtnIsVisible: false,
        }
    },
    components: {
        HeaderComponent,
        GalleryComponent,
        AboutUsComponent,
        ContactUsComponent,
        FormComponent,
        FooterComponent,
    },
    methods: {
        handleScroll() {
            const headerHeight = this.$refs.headerComponent.$el.offsetHeight;
            this.scrollTopBtnIsVisible = window.scrollY > headerHeight;
        },
        scrollToTop() {
        window.scrollTo({
            top: 0,
        });
        },
    },
    async mounted() {
        this.handleScroll();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>


<style>
.home-page-component-container {
    /* min-height: 2500px; */
    position: relative;
}

#scrollTopBtn {
    width: 75px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    z-index: 50;
    /* display: none; Button is hidden by default */
}

#scrollTopBtn img {
    width: 100%;
}

#scrollTopBtn img:hover {
    /* box-shadow: 0 0 20px black; */
    transform: matrix3d(1, 0, 0.00, 0, 0.00, 1, 0.00, 0, 0, 0, 1, 0, 0, -2.5, 0, 1);
    transition-duration: 350ms;
}

.home-page-component-container .horizontal-separator {
    width: calc(100% - 50px);
    margin: auto;
    border: 1px solid #743332;
}

/* Small to medium mobile devices (576px to 767px) */
@media (max-width: 767px) {
    #scrollTopBtn {
        width: 70px;
    }
}

/* Small mobile devices (320px to 576px) */
@media (min-width: 321px) and (max-width: 576px) {
    #scrollTopBtn {
        width: 60px;
    }
}

/* Smallest mobile devices (max 320px) */
@media only screen and (max-width: 320px) {
    #scrollTopBtn {
        width: 55px;
    }
}


</style>