<template>
    <div class="contact-us-component-container" id="contact">
        <section class="contact-us-section-container">
            <h2>contact us</h2>
            <div class="contact-us-contents-container">
                <div class="contact-us-list-container">
                    <ul class="contact-us-list">
                        <li>
                            <div class="contact-img-container">
                                <img src="../assets/icons/telephone.png" alt="contact-img">
                            </div>
                            <div class="contact-info-container phone-number">
                                <span class="mobile">917-535-0387</span>
                                <span class="separator">&sol;</span>
                                <span class="mobile">917-440-6918</span>
                            </div>
                        </li>
                        <li>
                            <div class="contact-img-container">
                                <img src="../assets/icons/office_phone.png" alt="contact-img">
                            </div>
                            <div class="contact-info-container">
                                <span class="office-telephone">718-713-7711</span>
                            </div>
                        </li>
                        <li>
                            <div class="contact-img-container">
                                <img src="../assets/icons/fax.png" alt="contact-img">
                            </div>
                            <div class="contact-info-container">
                                <span class="fax">718-713-5550</span>
                            </div>
                        </li>
                        <li>
                            <div class="contact-img-container">
                                <img src="../assets/icons/mail.png" alt="contact-img">
                            </div>
                            <div class="contact-info-container">
                                <span class="email">contact@orbstarconstruction.com</span>
                            </div>
                        </li>
                        <li>
                            <div class="contact-img-container">
                                <img src="../assets/icons/location.png" alt="contact-img">
                            </div>
                            <div class="contact-info-container">
                                <span class="email">48&nbsp;&nbsp;72 ST B1, Brooklyn, NY 11209</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="locator-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.7507827934896!2d-74.03816052348508!3d40.635377742302076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24ff98b82f199%3A0xbbeb08f000eb771b!2s48%2072nd%20St%2C%20Brooklyn%2C%20NY%2011209%2C%20Verenigde%20Staten!5e0!3m2!1snl!2snl!4v1711734337014!5m2!1snl!2snl"
                        width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </section>
        <div class="horizontal-separator"></div>
    </div>
</template>

<script>
export default {
    name: 'ContactUsComponent',
}
</script>

<style>
    .contact-us-component-container {
        width: 100%;
        /* padding: 0 25px; */
    }

    .contact-us-component-container .contact-us-section-container {
        padding: 0 25px 40px;
    }

    .contact-us-component-container h2 {
        padding: 25px 0 20px;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        font-size: 40px;
        text-transform: uppercase;
        color: #743332;
        /* border: 1px solid; */
    }

    .contact-us-component-container .contact-us-contents-container {
        height: 350px;
        display: flex;
        align-items: center;
        padding: 25px 0;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border-radius: 5px;
    }

    .contact-us-component-container .contact-us-list-container {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
        border-right: 2px solid #743332;
    }

    .contact-us-component-container .contact-us-list {
        width: max-content;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        /* justify-content: center;
        align-items: center; */
    }

    .contact-us-component-container .contact-us-list li {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 5px 20px;
    }

    .contact-us-component-container .contact-us-list .contact-img-container {
        width: 4.5vw;
        max-width: 50px;
        margin-right: 25px;
        /* border: 1px solid red; */
    }

    .contact-us-component-container .contact-us-list .contact-img-container img {
        width: 100%;
        filter: brightness(0) saturate(100%) invert(23%) sepia(24%) saturate(1548%) hue-rotate(314deg) brightness(92%) contrast(87%);
    }

    .contact-us-component-container .contact-us-list .contact-info-container span {
        /* font-size: 30px; */
        font-size: calc(10px + 1.8vw);
        color: #743332;
        font-family: 'Arvo Std', Verdana, Geneva, Tahoma, sans-serif;
        letter-spacing: 1px;
        /* padding: 0 20px; */
    }

    .contact-us-component-container .contact-info-container .separator::after {
        content: ' ';
    }

    .contact-us-component-container .contact-info-container .separator::before {
        content: ' ';
    }

    .contact-us-component-container .locator-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        /* border: 1px solid red; */
    }

    .contact-us-component-container .locator-container iframe {
        width: calc(100% - 40px);
        max-width: 400px;
        border-radius: 5px;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (max-width: 1199px) {
        .contact-us-component-container h2 {
            font-size: calc(10px + 2.5vw);
        }

        .contact-us-component-container .contact-us-list .contact-info-container span {
            font-size: calc(16px + 1vw);
        }
    }

    /* Small devices (tablets, 768px and up) */
    @media (max-width: 991px) {
        .contact-us-component-container .contact-us-list .contact-info-container span {
            font-size: calc(10px + 1.2vw);
        }
    }

    /* Small to medium mobile devices (576px to 767px) */
    @media (max-width: 767px) {
        .contact-us-component-container .contact-us-contents-container {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: max-content;
        }

        .contact-us-component-container .contact-us-list-container {
            border: none;
            width: 100%;
            max-width: 80%;
            /* border: 1px solid red; */
        }

        .contact-us-component-container .contact-us-list {
            width: 100%;
            max-width: 95%;
            justify-content: center;
            /* align-items: center; */
            padding: 0 0 25px 0;
        }

        .contact-us-component-container .contact-us-list li {
            /* width: 75%; */
            width: 410px;
            margin: 0 auto;
            padding: 0;
            /* border: 1px solid red; */
        }

        .contact-us-component-container .contact-us-list .contact-img-container {
            width: 10%;
            min-width: 40px;
            margin: 0;
        }

        .contact-us-component-container .contact-us-list .contact-info-container {
            padding-left: 15px;
        }

        .contact-us-component-container .contact-us-list .contact-info-container span {
            font-size: calc(10px + 1.3vw);
        }

        .contact-us-component-container .locator-container {
            width: 95%;
        }

        .contact-us-component-container .locator-container iframe {
            max-width: 80%;

        }
    }

    /* Small mobile devices (320px to 576px) */
    @media (min-width: 321px) and (max-width: 576px) {
        .contact-us-component-container h2 {
            padding: 25px 0;
            margin: 0;
            text-align: center;
        }

        .contact-us-component-container .contact-us-list-container {
            max-width: calc(100% - 40px);
        }

        .contact-us-component-container .contact-us-list {
            max-width: 100%;
        }

        .contact-us-component-container .contact-us-list li {
            padding: 5px 0;
            width: 95%;
        }

        .contact-us-component-container .contact-us-list .contact-img-container {
            min-width: 20px;
            max-width: 30px;
        }

        .contact-us-component-container .contact-us-list .contact-info-container {
            width: 41vw;
            padding-left: 10px;
            display: flex;
            align-items: center;
        }

        .contact-us-component-container .contact-us-list .contact-info-container span {
            font-size: 2.9vw;
        }

        .contact-us-component-container .contact-us-list .contact-info-container .separator {
            display: none;
        }

        .contact-us-component-container .contact-us-list .phone-number {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
        }

        .contact-us-component-container .locator-container {
            width: 100%;
        }

        .contact-us-component-container .locator-container iframe {
            max-width: 100%;

        }
    }

    /* Smallest mobile devices (max 320px) */
    @media only screen and (max-width: 320px) {
        .contact-us-component-container h2 {
            padding: 25px 0;
            margin: 0;
            text-align: center;
        }

        .contact-us-component-container .contact-us-list-container {
            max-width: calc(100% - 40px);
        }

        .contact-us-component-container .contact-us-list {
            max-width: 100%;
            /* border: 1px solid red; */
        }

        .contact-us-component-container .contact-us-list li {
            padding: 5px 0;
            max-width: 250px;
            /* justify-content: center; */
            /* border: 1px solid red; */
        }

        .contact-us-component-container .contact-us-list .contact-img-container {
            min-width: 0px;
            max-width: 20px;
        }

        .contact-us-component-container .contact-us-list .contact-info-container span {
            font-size: calc(8px + 0.7vw);
        }

        .contact-us-component-container .contact-us-list .contact-info-container .separator {
            display: none;
        }

        .contact-us-component-container .contact-us-list .phone-number {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
        }

        .contact-us-component-container .locator-container {
            width: 100%;
        }

        .contact-us-component-container .locator-container iframe {
            max-width: 100%;

        }
    }

</style>