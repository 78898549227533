import { createWebHistory, createRouter } from 'vue-router';
import HomePage from '../components/HomePage.vue';

// Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: 'HomePage',
        component: HomePage,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.NODE_ENV === '/'),
    routes,
});

export default router;